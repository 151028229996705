export const types = {
  SET_VISIBLE: 'SET_VISIBLE',
}

export const actions = {
  setVisible: (id, visible) => ({
    type: types.SET_VISIBLE,
    payload: {
      id,
      visible,
    },
  }),
}
