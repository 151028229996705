import React from 'react'
import { Helmet } from 'react-helmet'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { StoreProvider, reducer, initialState } from 'store'

import theme from 'src/theme'

const TopLayout = ({ children }) => (
  <>
    <Helmet>
      <html lang="nl" />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="business.business" />
      <meta property="og:url" content="/" />
      <title>Sibyl Heijnen</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <StoreProvider initialState={initialState} reducer={reducer}>
        {children}
      </StoreProvider>
    </ThemeProvider>
  </>
)

export default TopLayout
