import { types } from './actions'

/* eslint-disable consistent-return */
const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_VISIBLE:
      return {
        ...state,
        visible: {
          ...state.visible,
          [action.payload.id]: action.payload.visible,
        },
      }
    default:
      break
  }
}

export default reducer
