import { createTheme, responsiveFontSizes } from '@mui/material/styles'

// A custom theme for this app
//
const theme = createTheme({
  typography: {
    fontFamily: ['Helvetica Neue', 'Helvetica', 'Roboto', 'sans-serif'].join(
      ',',
    ),
    h1: {
      fontWeight: 400,
    },
    h2: {
      textAlign: 'center',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#b31b31',
    },
  },
})

export default responsiveFontSizes(theme)
